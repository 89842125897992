import { faTwitter, faFacebook, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ButtonLink } from "components/common/Button";
import Container from "components/common/Container";
import Link from "components/common/Link";
import Logo from "components/common/Logo";
import { SUPPORT_EMAIL } from "constants/site";
import {
  HOME_URL,
  CONTACT_URL,
  YOUTUBE_URL,
  TWITTER_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  PRIVACY_URL,
  TERMS_URL,
  DMCA_URL,
} from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import { useAssertSecondaryMenuItemsContext } from "hooks/useMenuItemsContext";
import { NewsletterSignup } from "./NewsletterSignup";
import styles from "./LayoutFooter.module.css";

const CURRENT_YEAR = new Date().getFullYear();

export default function LayoutFooter() {
  const menuItems = useAssertSecondaryMenuItemsContext();
  return (
    <footer className={styles.root}>
      <NewsletterSignup />
      <Container>
        <div className={styles.footerHead}>
          <div className={styles.footerLogoImage}>
            <Link href={HOME_URL}>
              <Logo />
            </Link>
          </div>
          <div className={styles.footerHeadText}>
            Need assistance? Contact{" "}
            <b>
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </b>
          </div>
        </div>
        <div className={styles.footerMenu}>
          {menuItems.map(menuItem =>
            menuItem.childItems?.edges?.length ? (
              <div key={menuItem.id} className={styles.footerMenuColumn}>
                <div className={styles.footerMenuColumTitle}>{menuItem.label}</div>
                <ul>
                  {mapNodes(
                    menuItem.childItems,
                    item =>
                      item?.path && (
                        <li key={item.id} className="my-6 flex">
                          {item.path === CONTACT_URL ? (
                            <div>
                              <ButtonLink href={item.path}>{item.label}</ButtonLink>
                            </div>
                          ) : (
                            <Link className={styles.footerMenuColumLink} href={item.path}>
                              {item.label}
                            </Link>
                          )}
                        </li>
                      )
                  )}
                </ul>
              </div>
            ) : (
              <Link
                key={menuItem.id}
                className={styles.footerMenuColumLink}
                href={menuItem.path || ""}
              >
                {menuItem.label}
              </Link>
            )
          )}
        </div>

        <div className={styles.footerFoot}>
          <div className={styles.copyRight}>
            {CURRENT_YEAR} © Glenn Marks, Ph.D. Director ® 1995-
            {CURRENT_YEAR} ZUR INSTITUTE. All rights reserved.{" "}
            <Link href={PRIVACY_URL}>Privacy</Link> - <Link href={TERMS_URL}>Terms</Link> -{" "}
            <Link href={TERMS_URL}>Disclaimer</Link> - <Link href={DMCA_URL}>DMCA</Link>
          </div>
          <div className={styles.socialMenu}>
            <Link className={styles.socialLink} href={YOUTUBE_URL}>
              <FontAwesomeIcon className={styles.socialIcon} icon={faYoutube} />
            </Link>
            <Link className={styles.socialLink} href={TWITTER_URL}>
              <FontAwesomeIcon className={styles.socialIcon} icon={faTwitter} />
            </Link>
            <Link className={styles.socialLink} href={FACEBOOK_URL}>
              <FontAwesomeIcon className={styles.socialIcon} icon={faFacebook} />
            </Link>
            <Link className={styles.socialLink} href={LINKEDIN_URL}>
              <FontAwesomeIcon className={styles.socialIcon} icon={faLinkedin} />
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
