import { useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import gql from "graphql-tag";
import { useMemo } from "react";
import { CERTIFICATE_PROGRAMS_SLUG, PACKAGES_URL } from "constants/urls";
import {
  CertificateProgramsQueryNodes as Product,
  CertificateProgramsQueryQuery as CertificateProgramsQuery,
  CertificateProgramsQueryVariables,
} from "hooks/__generated__/useCertificatePrograms";

export const CERTIFICATE_PROGRAMS_QUERY = gql`
  query CertificateProgramsQuery($slug: ID!) {
    productCategory(id: $slug, idType: SLUG) {
      id

      # As of WooGraphQL 0.10.6, the where connection args are not actually working
      products(first: 100, where: { type: BUNDLE, visibility: VISIBLE }) {
        nodes {
          id
          name
          slug
          # As of WooGraphQL 0.10.6, catalogVisibility requires the edit post capability, so we've added our own to filter on
          catalogVisibilityZur
        }
      }
    }
  }
`;

export function useCertificatePrograms() {
  const { data, loading } = useEnhancedQuery<
    CertificateProgramsQuery,
    CertificateProgramsQueryVariables
  >(CERTIFICATE_PROGRAMS_QUERY, {
    variables: { slug: CERTIFICATE_PROGRAMS_SLUG },
  });

  const mapProduct = (product: Product) => {
    return {
      ...product,
      name: product.name?.replace(/^Certificate Program in /, "").replace(/\(.*\)/, ""),
      catalogPath: makeUrl(PACKAGES_URL, { slug: product.slug || "" }),
    };
  };

  const sortProduct = (a: Product, b: Product) => {
    return (a.name?.toLowerCase() || "").localeCompare(b.name?.toLowerCase() || "");
  };

  return useMemo(() => {
    if (loading) {
      return [];
    }
    return (
      data?.productCategory?.products?.nodes
        ?.filter(notEmpty)
        .filter(product => product.catalogVisibilityZur === "VISIBLE")
        .sort(sortProduct)
        .map(mapProduct) || []
    );
  }, [data, loading]);
}
