import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import { parseISO } from "date-fns";
import { useMemo } from "react";
import { EventIdType, ShowInListingsEnum } from "__generated__/globalTypes";
import { RESOURCE_FRAGMENT } from "components/BundleResources/queries";
import {
  EventForUserQueryEvent,
  EventForUserQueryProduct,
  EventForUserQueryQuery as EventForUserQuery,
  EventForUserQueryVariables,
  EventSectionsQueryQuery as EventSectionsQuery,
  EventsQueryEdges,
  EventsQueryQuery as EventsQuery,
  EventViewQueryEvent,
  EventViewQueryProduct,
  EventViewQueryQuery as EventViewQuery,
  EventViewQueryTimestamps,
  EventViewQueryVariables,
} from "components/events/__generated__/queries";

export type {
  EventViewQuery as EventQuery,
  EventViewQueryVariables as EventQueryVariables,
  EventViewQueryTimestamps as EventTimestamps,
  EventSectionsQuery,
};
export type { EventsQuery };

export type CombinedEvent = EventViewQueryEvent & EventForUserQueryEvent;
export type CombinedEventProduct = EventViewQueryProduct & EventForUserQueryProduct;

export const EVENT_RESOURCES_FRAGMENT = gql`
  ${RESOURCE_FRAGMENT}
  fragment EventResourcesFragment on Event_Eventfields {
    resources {
      id
      ...ResourceInfo
    }
    bundledWith {
      id
      eventFields {
        resources {
          id
          ...ResourceInfo
        }
      }
    }
  }
`;

const EVENT_FIELDS_FRAGMENT = gql`
  fragment EventFieldsFragment on Event_Eventfields {
    showInListings
    registerLink
    eventStartDateAndTime
    eventEndTime
    eventShortTitle
    eventExcerpt
    eventGroup
    presenterName
    presenterImage {
      id
      altText
      mediaItemUrl
      mediaDetails {
        height
        width
      }
    }
    bundledWebinars {
      ... on Event {
        id
        title
        eventFields {
          eventShortTitle
          eventStartDateAndTime
        }
      }
    }
  }
`;

const EVENT_FRAGMENT = gql`
  ${EVENT_FIELDS_FRAGMENT}
  fragment EventInfo on Event {
    id
    date
    title
    slug
    content # summary
    eventFields {
      ...EventFieldsFragment
    }
    timestamps {
      end
    }
  }
`;

export const EVENT_QUERY = gql`
  ${EVENT_FRAGMENT}
  ${EVENT_RESOURCES_FRAGMENT}
  query EventViewQuery($id: ID!, $idType: EventIdType!) {
    event(id: $id, idType: $idType) {
      id
      ...EventInfo
      product {
        id
        sku
        databaseId
        priceUSD
        name
        description
        type
        events {
          id
          title
        }
      }
      course {
        id
        slug
        displayInCatalog
      }
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphImage {
          id
          mediaItemUrl
        }
      }
      timestamps {
        start
        end
      }
      eventFields {
        ...EventFieldsFragment
        eventType
        customBoardApprovals
        eventStartDateAndTime
        eventExcerpt
        eventOutline
        eventObjectives
        eventTechnicalInformation
        fieldGroupName
        ceUnits
        introText
        presenterName
        presenterBio
        presenterImage {
          id
          authorId
          author {
            node {
              id
              firstName
              lastName
              description
            }
          }
          uri
          mediaDetails {
            height
            width
          }
          mediaItemUrl
          altText
        }
        price
        isPurchasable
        registerLink
        handoutUrl
        video
        bundledWebinars {
          ...EventInfo
        }
        ...EventResourcesFragment
      }
      subscriptionFeatureOptIns {
        membersExclusive
      }
    }
  }
`;

export const EVENT_FOR_USER_QUERY = gql`
  query EventForUserQuery($id: ID!, $idType: EventIdType!) {
    event(id: $id, idType: $idType) {
      id
      isRegistered
      userEventDetails {
        joinUrl
        didAttend
      }
      webinarDetails {
        hasParticipationPulled
      }
      course {
        id
        courseFields {
          isWebinarRecordingAvailable
        }
      }
      product {
        id
        couponForUser {
          code
          discountedPriceUSD
        }
        userDiscountsFromSubscription {
          id
          displayName
          percent
          fixedPrice
          subscriptionId
          productFeatureId
          usageLimit
          usageCount
          ceLimit
          ceCount
        }
        hasPurchased
      }
    }
  }
`;

// change how to support ParsedUrlParams?
export type UseEventParams = {
  id?: unknown;
  slug?: unknown;
};

export const getEventVariables = ({ id: eventId, slug: eventSlug }: UseEventParams) => {
  const id = eventSlug || eventId;
  const idType = eventSlug ? EventIdType.SLUG : EventIdType.DATABASE_ID;

  return {
    id: id as string,
    idType,
  };
};

export const useEvent = ({ id: eventId, slug: eventSlug }: UseEventParams) => {
  const variables = getEventVariables({ id: eventId, slug: eventSlug });

  return useEnhancedQuery<EventViewQuery, EventViewQueryVariables>(EVENT_QUERY, {
    variables,
    skip: !variables.id,
  });
};

export const useEventForUser = ({ id: eventId, slug: eventSlug }: UseEventParams) => {
  const variables = getEventVariables({ id: eventId, slug: eventSlug });

  return useEnhancedQuery<EventForUserQuery, EventForUserQueryVariables>(EVENT_FOR_USER_QUERY, {
    variables,
    skip: !variables.id,
  });
};

export const EVENTS_QUERY = gql`
  ${EVENT_FRAGMENT}
  query EventsQuery {
    events(first: 100, where: { relativeStartTime: FUTURE }) {
      edges {
        node {
          id
          ...EventInfo
        }
      }
    }
  }
`;

export const EVENT_SECTIONS_QUERY = gql`
  query EventSectionsQuery {
    eventSections {
      eventSections {
        sections {
          group
          sectionTitle
          sectionDescription
        }
      }
    }
  }
`;

export const WEBINAR_WORDING_QUERY = gql`
  query WebinarWordingQuery {
    webinarWording {
      webinarWording {
        webinarWordingContent
        webinarWordingTitle
      }
    }
  }
`;

const sortByEventDate = (eventEdgeA: EventsQueryEdges, eventEdgeB: EventsQueryEdges) => {
  const { node: eventA } = eventEdgeA;
  const { node: eventB } = eventEdgeB;

  if (!eventA?.eventFields?.eventStartDateAndTime || !eventB?.eventFields?.eventStartDateAndTime)
    return 0;

  const dateA = parseISO(eventA.eventFields.eventStartDateAndTime);
  const dateB = parseISO(eventB.eventFields.eventStartDateAndTime);

  return dateA.getTime() - dateB.getTime();
};

export const useEventsList = (filterByShowInListings = false) => {
  const result = useEnhancedQuery<EventsQuery>(EVENTS_QUERY);

  return useMemo(() => {
    if (!result?.data?.events) return result;

    const showInListings = (event: EventsQueryEdges) =>
      filterByShowInListings
        ? event.node?.eventFields?.showInListings !== ShowInListingsEnum.NEVER
        : true;

    // sort the events by the eventStartDateAndTime field. This will break pagination because the cursor will be off.
    // TODO: need to implement this sorting on the server-side
    return {
      ...result,
      data: {
        ...result.data,
        events: {
          ...result.data.events,
          edges: Array.isArray(result.data.events.edges)
            ? result.data.events.edges.filter(notEmpty).filter(showInListings).sort(sortByEventDate)
            : [],
        },
      },
    };
  }, [filterByShowInListings, result]);
};
